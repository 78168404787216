import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { BsFillArrowLeftCircleFill, BsCupStraw, BsBag } from "react-icons/bs"
import { FaGifts, FaSun, FaMoon } from "react-icons/fa"
import { VscSymbolMisc } from "react-icons/vsc"
import { TbHanger } from "react-icons/tb"
import { RiEarthLine } from "react-icons/ri"
import Carousel from "@components/Carousel"
import { size } from "../util/breakpoints"

const ButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eee;
  margin: 0 1em;

  @media (max-width: ${size.laptopL}) {
    svg {
      font-size: 0.7em !important;
    }
  }

  @media (max-width: ${size.mobileL}) {
    margin: 0.2em 0.4em;
    padding: 0.15em;

    svg {
      font-size: 0.6em !important;
    }
  }

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  svg {
    font-size: 0.9em;
  }
`

const TitleStyle = styled.h1`
  padding: 20px 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media (max-width: ${size.laptop}) {
      font-size: 0.9em !important;
      padding: 0px;
    }

    @media (max-width: ${size.tablet}) {
      font-size: 0.9em !important;
      padding: 0px;
    }

    @media (max-width: ${size.mobileM}) {
      font-size: 0.6em !important;
      padding: 0px;
    }
  }
`

const FilterStyle = styled.h4`
  background: #444;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px auto;
  padding: 0.35em;
  color: #f4f4f4;

  .filter {
    position: relative;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.45em 0;
    opacity: 0.95;
    margin: 0em 0.8em;
    font-size: 2vw;

    @media (max-width: ${size.laptopL}) {
      padding: 0.5em 0;
      margin: 0em 0.6em;
      font-size: 1em;
    }

    @media (max-width: ${size.tablet}) {
      padding: 0.25em 0;
      margin: 0em 0.4em;
      font-size: 1em;
    }

    @media (max-width: ${size.mobileL}) {
      padding: 0;
      margin: 0.55em 0.1em;
      font-size: 0.8em;
      width: 30%;
    }

    &.active {
      color: #1094c9 !important;
      opacity: 0.99 !important;
      text-shadow: 1px 1px 2px #04212c;

      svg {
        color: #1094c9;
        font-size: 1.1em;
      }
    }

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }

    svg {
      font-size: 0.8em;
      color: #ddd;
      margin: 0 9px 0 0;
      text-shadow: 0px 0px 2px #111;
    }
  }
`

const categories = [
  {
    name: "all",
    regex: "/(apparel)|(misc)|(drinkware)|(bags)/",
    icon: <RiEarthLine />,
    filter: 0,
    subfilters: [],
  },
  {
    name: "apparel",
    regex: "/(apparel)/",
    icon: <TbHanger />,
    filter: 1,
    subfilters: [
      {
        name: "All",
        filter: "all",
      },
      {
        name: "Jackets/Hoodies",
        filter: "jacket",
      },
      {
        name: "T-Shirts",
        filter: "tshirt",
      },
      {
        name: "Hats",
        filter: "hat",
      },
      {
        name: "Beanies",
        filter: "beanie",
      },
      {
        name: "Other",
        filter: "otherApparel",
      },
    ],
  },
  {
    name: "bags",
    icon: <BsBag />,
    filter: 2,
    subfilters: [
      {
        name: "All",
        filter: "all",
      },
      {
        name: "Duffels",
        filter: "duffel",
      },
      {
        name: "Coolers",
        filter: "cooler",
      },
      {
        name: "Backpacks",
        filter: "backpack",
      },
      {
        name: "Other",
        filter: "otherBag",
      },
    ],
  },
  {
    name: "drinkware",
    icon: <BsCupStraw />,
    filter: 3,
    subfilters: [
      {
        name: "All",
        filter: "all",
      },
      {
        name: "Water Bottles",
        filter: "bottle",
      },
      {
        name: "Mugs",
        filter: "mug",
      },
      {
        name: "Tumblers",
        filter: "tumbler",
      },
    ],
  },
  {
    name: "misc",
    icon: <VscSymbolMisc />,
    filter: 4,
    subfilters: [
      {
        name: "All",
        filter: "all",
      },
      {
        name: "Tech Gifts",
        filter: "a",
      },
      {
        name: "Stickers",
        filter: "b",
      },
      {
        name: "Patches",
        filter: "c",
      },
      {
        name: "Blankets",
        filter: "d",
      },
      {
        name: "Other",
        filter: "e",
      },
    ],
  },
]

export default function Gallery() {
  const [filter, setFilter] = useState("all")
  const [subFilter, setSubFilter] = useState("all")
  const [color, setColor] = useState("#aaa")

  function selectFilter(f) {
    setSubFilter("all")
    setFilter(f)
  }

  const catObject = categories.find(f => f.name === filter)

  return (
    <div>
      <TitleStyle
        style={{
          color: color === "#555" ? "#e6e6e6" : "#eee",
          background: color === "#555" ? "#272727" : "#256985",
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <ButtonStyle>
            <BsFillArrowLeftCircleFill />
          </ButtonStyle>
        </Link>
        <div className="title">Reel Crew Gifts</div>
        <ButtonStyle>
          {color === "#555" ? (
            <FaSun onClick={() => setColor("#aaa")} />
          ) : (
            <FaMoon onClick={() => setColor("#555")} />
          )}
        </ButtonStyle>
      </TitleStyle>
      <FilterStyle>
        {categories.map((c, i) => {
          return (
            <div
              key={i}
              className={`filter${filter === c.name ? " active" : ""}`}
              onClick={() => selectFilter(c.name)}
            >
              {c.icon} {c.name[0].toUpperCase() + c.name.slice(1)}
            </div>
          )
        })}
      </FilterStyle>
      <Carousel filter={filter} color={color} />
    </div>
  )
}

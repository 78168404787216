import React from "react"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { size } from "../util/breakpoints"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const CarouselStyle = styled.div`
  margin: 0px;
  padding: 1em 1em 3em 1em;
  background-image: url("https://www.transparenttextures.com/patterns/asfalt-dark.png");

  button.slick-prev::before,
  button.slick-next::before {
    font-size: 40px !important;

    @media (max-width: ${size.laptopL}) {
      font-size: 20px !important;
    }
  }

  ul.slick-dots {
    color: white !important;

    li {
      button::before {
        color: white !important;
      }
    }
  }
`
const Empty = styled.p`
  color: white !important;
  font-weight: bold;
  font-size: 3.15em;
  color: #2aace0 !important;
  background: #222;
  padding: 2vh 6vh;
  display: flex;
  width: fit-content !important;
  margin: 6vh auto !important;
  border-radius: 4px;

  @media (max-width: ${size.tablet}) {
    font-size: 1.45em;
  }
`
const ImageStyle = styled.div`
  margin: 0 auto;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: ${size.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  img {
    padding: 10px;
  }
`
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

const getDir = filter => {
  switch (filter) {
    case "apparel":
      return "/(apparel)/"
    case "misc":
      return "/(misc)/"
    case "drinkware":
      return "/drinkware/"
    case "bags":
      return "/bags/"
    case "all":
      return "/(apparel)|(misc)|(drinkware)|(bags)/"
    default:
      return "/(apparel)|(misc)|(drinkware)|(bags)/"
  }
}

export default function Carousel({ filter, color }) {
  const { allFile } = useStaticQuery(graphql`
    query allFile {
      allFile(
        filter: {
          extension: { regex: "/(png)|(jpg)/" }
          dir: { regex: "/(apparel)|(misc)|(drinkware)|(bags)/" }
        }
      ) {
        edges {
          node {
            id
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const filteredItems =
    allFile && allFile.edges.length > 0
      ? allFile.edges.filter(f =>
          filter === "all" ? true : f.node.relativeDirectory === filter
        )
      : []

  return (
    <CarouselStyle
      style={{
        backgroundColor: color ? color : "#111",
        boxShadow:
          color === "#555"
            ? "inset 0px 4px 7px #1e343d"
            : "inset 0px 4px 7px #91d5f0",
      }}
    >
      {filteredItems.length > 0 && (
        <ImageStyle>
          {shuffle(filteredItems).map((image, i) => {
            return (
              <GatsbyImage
                durationFadeIn={700}
                fadeIn
                fluid={image.node.childImageSharp.fluid}
                alt=""
                key={i}
              />
            )
          })}
        </ImageStyle>
      )}
    </CarouselStyle>
  )
}
